export default function siteScript() {
  window.addEventListener("scroll", function () {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowheight = window.innerHeight;
      var revealtop = reveals[i].getBoundingClientRect().top;
      var revealpoint = 150;

      if (revealtop < windowheight - revealpoint) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  });
  //adding parallax effect to the hero section title
  //learned this from Youtube Tutorial - by Online Tutorials - https://www.youtube.com/watch?v=1wfeqDyMUx4

  // let titleOne = document.getElementById("title-one");
  // let titleTwo = document.getElementById("title-two");

  // window.addEventListener("scroll", function () {
  //   let value = window.scrollY;

  //   titleOne.style.right = value * 0.5 + "px";
  //   titleTwo.style.left = value * 0.5 + "px";
  // });

  let postOne = document.getElementById("post-one");
  let postTwo = document.getElementById("post-two");

  window.addEventListener("scroll", function () {
    let value = window.scrollY;

    postOne.style.right = value * 0.5 + "px";
    postTwo.style.left = value * 0.5 + "px";
  });
}

import "./menu.css";

const Menu = ({ menu }) => {
  const drawMenu = () => {
    const menuContainer = document.getElementById("menu");

    Object.keys(menu).forEach((category) => {
      const categoryDiv = document.createElement("div");
      categoryDiv.className = "menu-category";

      const categoryTitle = document.createElement("h2");
      categoryTitle.textContent = category;
      categoryDiv.appendChild(categoryTitle);

      menu[category].forEach((item) => {
        const itemDiv = document.createElement("div");
        itemDiv.className = "menu-item";

        const itemName = document.createElement("span");
        itemName.textContent = item.nombre;
        const itemPrice = document.createElement("span");
        itemPrice.textContent = `$${item.precio.COP.toLocaleString()} COP`;

        console.log(itemName);
        console.log(itemPrice);

        itemDiv.appendChild(itemName || "");
        itemDiv.appendChild(itemPrice || 0);
        categoryDiv.appendChild(itemDiv);
      });

      menuContainer.appendChild(categoryDiv);
    });
  };

  return (
    <section id="menu">
      <div className="header-pringamosa">
        <h1>La Pringamosa</h1>
        <p>¡Sabor, tradición y momentos inolvidables!</p>
      </div>

      <div id="menu"></div>

      {drawMenu()}
    </section>
  );
};

export default Menu;

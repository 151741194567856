import { QRCode } from "react-qrcode-logo";
import Menu from "../../_components/common/Menu";
import logo from "./logo_.png";
import { useSelector } from "react-redux";
import "./site.css";

const LaPringamosaHome = () => {
  const { menu, loading, message, error } = useSelector(
    (state) => state.pringamosa
  );
  return (
    <>
      {/* Menu Seccion */}
      <Menu menu={menu} />
      <div className="div-center">
        <QRCode
          value="https://loretta3.com/landing/la-pringamosa#menu"
          logoImage={logo}
          size={256}
          logoHeight={80}
          logoWidth={100}
          qrStyle="dots"
          eyeRadius={10}
        />
      </div>
    </>
  );
};

export default LaPringamosaHome;

import { Row, Col, Button } from "reactstrap";

export const whatsAppBt = ({ whatsapp }) => {
  return (
    <>
      <link
        rel="stylesheet"
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css"
      />
      <a
        href={`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hola%21%20Quiero%20m%C3%A1s%20informaci%C3%B3n`}
        className="float"
        target="_blank"
      >
        <i className="fa fa-whatsapp my-float"></i>
      </a>
    </>
  );
};

export const scrollTop = () => {
  return (
    <div id="scroll-Top">
      <div className="return-to-top">
        <i
          className="fa fa-angle-up "
          id="scroll-top"
          data-toggle="tooltip"
          data-placement="top"
          title=""
          data-original-title="Back to Top"
          aria-hidden="true"
        ></i>
      </div>
    </div>
  );
};

import { Container } from "react-bootstrap";
import SEO from "./SEO";
import "./web.css";
import logo from "../assets/images/loretta3.png";
import logo2 from "../assets/images/logo.png";
import logo3 from "../assets/images/logo_woutback.png";
import logo4 from "../assets/images/logo-pilares.png";

import { useEffect } from "react";
import siteScript from "./script";
import NavBar from "./NavBar";
import Footer from "./Footer";
import About from "./About";
import Products from "./Products";
import Contact from "./Contact";
import Landing from "./Landing";
import Post from "./Post";
import Customers from "./Customers";
import { useSelector } from "react-redux";

const Web = () => {
  useEffect(() => {
    siteScript();
  });
  const { title, description, keywords, url, image, robots, author } =
    useSelector((state) => state.site);

  return (
    <>
      <Container>
        <SEO
          title={title}
          description={description}
          keywords={keywords}
          url={url}
          image={image}
          robots={robots}
          author={author}
        />
        {/* <!--Nav Section--> */}
        <NavBar />

        {/* <!-- Landing Section  --> */}

        {/* <Landing logo={logo} logo2={logo2} /> */}
        <Post logo={logo4} />
        {/* <LorettaPost /> */}

        {/* <!-- About Us  --> */}

        <About />

        {/* <!-- Product Section --> */}
        <Products logo={logo2} />

        {/* <!-- Customer Section --> */}
        {/* <Customers /> */}

        {/* <!-- Blog Section --> */}
        {/* <Blog /> */}

        {/* <!-- Call To Action Section --> */}
        <Contact />

        {/* <!-- Footer Section --> */}
        <Footer />
      </Container>
    </>
  );
};

export default Web;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";

export const fetch = createAsyncThunk(
  `${siteConstants.CUST_PRINGAMOSA}/fecth`,
  async (payload) => {
    return [];
  }
);

// src/reducers/rootReducer.js
const initialState = {
  id: "la-pringamosa",
  name: "La Pringamosa",
  slogan: "Sabor, tradición y momentos inolvidables",
  type: "Restaurante, Bar y Salón de Eventos",
  title: "Restaurante Bar y Salón de Eventos en Zipaquirá",
  seo: {
    title: "La Pringamosa | Restaurante Bar y Salón de Eventos en Zipaquirá",
    description:
      "Disfruta de La Pringamosa, el restaurante bar en la entrada de Zipaquirá. Ofrecemos comida típica, cocteles artesanales y espacios para eventos como matrimonios, cumpleaños y cenas románticas.",
    keywords:
      "restaurante en Zipaquirá, bar en Zipaquirá,salón de eventos Zipaquirá,comida típica colombiana,eventos empresariales,cenas románticas Zipaquirá,celebraciones Zipaquirá,La Pringamosa restaurante",
  },
  url: "https://loretta3.com/landing/la-pringamosa",
  image: "https://loretta3.com/images/la-pringamosa.png",
  robots: "index, follow",
  author: "Loretta3",

  menu: {
    "Bebidas Calientes": [
      { nombre: "Tinto", precio: { COP: 3000, USD: 1 } },
      { nombre: "Café / Latte", precio: { COP: 4000, USD: 1 } },
      { nombre: "Aromática de Frutas", precio: { COP: 5000, USD: 2 } },
      { nombre: "Aromática Sencilla", precio: { COP: 4000, USD: 1 } },
      { nombre: "Caneazo / Carajillo", precio: { COP: 9000, USD: 3 } },
      { nombre: "Vino Caliente", precio: { COP: 10000, USD: 3 } },
      { nombre: "Café Frappé", precio: { COP: 6000, USD: 2 } },
      { nombre: "Milo Caliente / Frío", precio: { COP: 7000, USD: 2 } },
      { nombre: "Capuccino Baileys", precio: { COP: 8000, USD: 3 } },
      { nombre: "Aguapanela con Almojábana", precio: { COP: 11000, USD: 3 } },
      {
        nombre: "Aromática Agua Bendita (De la casa)",
        precio: { COP: 9000, USD: 3 },
      },
    ],
    "Jugos Naturales": [
      { nombre: "En Agua", precio: { COP: 7000, USD: 2 } },
      { nombre: "En Leche", precio: { COP: 8000, USD: 3 } },
    ],
    Limonadas: [
      { nombre: "Limonada Natural", precio: { COP: 6000, USD: 2 } },
      { nombre: "Limonada de Panela", precio: { COP: 6000, USD: 2 } },
      { nombre: "Limonada Cerezada", precio: { COP: 9000, USD: 3 } },
      { nombre: "Limonada de Hierbabuena", precio: { COP: 9000, USD: 3 } },
      { nombre: "Limonada de Coco", precio: { COP: 9000, USD: 3 } },
      { nombre: "Limonada de Sandía", precio: { COP: 9000, USD: 3 } },
      { nombre: "Limonada Azul", precio: { COP: 10000, USD: 3 } },
    ],
    "Otras Bebidas": [
      { nombre: "Gaseosa Personal 250ml", precio: { COP: 4000, USD: 1 } },
      { nombre: "Gaseosa 1.5lts", precio: { COP: 8000, USD: 3 } },
      { nombre: "Cerveza Nacional", precio: { COP: 5000, USD: 2 } },
      { nombre: "Coronita", precio: { COP: 10000, USD: 3 } },
      { nombre: "Cerveza Club Colombia", precio: { COP: 6000, USD: 2 } },
      { nombre: "Cerveza Light", precio: { COP: 6000, USD: 2 } },
      { nombre: "Cerveza Importada", precio: { COP: 12000, USD: 4 } },
      { nombre: "Bretaña", precio: { COP: 5000, USD: 2 } },
    ],
    Cocteles: [
      { nombre: "Mojito", precio: { COP: 22000, USD: 6 } },
      { nombre: "Daikiri de Fresa", precio: { COP: 22000, USD: 6 } },
      { nombre: "Cuba Libre", precio: { COP: 22000, USD: 6 } },
      { nombre: "Piña Colada", precio: { COP: 22000, USD: 6 } },
      { nombre: "Coco Loco", precio: { COP: 22000, USD: 6 } },
      { nombre: "Tom Collins", precio: { COP: 22000, USD: 6 } },
      { nombre: "Copa de Vino Tinto / Blanco", precio: { COP: 11000, USD: 3 } },
    ],
    Entradas: [
      { nombre: "Morcilla", precio: { COP: 15000, USD: 4 } },
      { nombre: "Patacones con Hogao", precio: { COP: 15000, USD: 4 } },
      { nombre: "Nuggets", precio: { COP: 17000, USD: 5 } },
      { nombre: "Chicharrones", precio: { COP: 17000, USD: 5 } },
      { nombre: "Empanadas", precio: { COP: 17000, USD: 5 } },
    ],
    Parrillada: [
      { nombre: "Tabla de Carnes", precio: { COP: 35000, USD: 10 } },
      { nombre: "Chuleta o Lomo de Cerdo", precio: { COP: 38000, USD: 11 } },
      { nombre: "Pechuga", precio: { COP: 31000, USD: 9 } },
      { nombre: "Churrasco", precio: { COP: 38000, USD: 11 } },
      { nombre: "Costillas de Cerdo", precio: { COP: 32000, USD: 9 } },
      { nombre: "Alitas", precio: { COP: 31000, USD: 9 } },
      { nombre: "Parrilla La Pringamosa", precio: { COP: 42000, USD: 12 } },
      { nombre: "Adiciones", precio: { COP: 7000, USD: 2 } },
    ],
    "Típica Colombiana": [
      { nombre: "Huesitos de Marrano", precio: { COP: 40000, USD: 12 } },
      { nombre: "Lengua en Salsa", precio: { COP: 35000, USD: 10 } },
      { nombre: "Sobrebarriga Zipaquireña", precio: { COP: 35000, USD: 10 } },
      { nombre: "Bistec a Caballo", precio: { COP: 33000, USD: 9 } },
      { nombre: "Bandeja Paisa", precio: { COP: 33000, USD: 9 } },
      { nombre: "Frijolada", precio: { COP: 28000, USD: 8 } },
      { nombre: "Ajiaco Especial", precio: { COP: 28000, USD: 8 } },
    ],
  },
  message: null,
  loading: false,
  error: null,
};

const pringamosaSlice = createSlice({
  name: siteConstants.CUST_PRINGAMOSA,
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = pringamosaSlice.actions;
export default pringamosaSlice.reducer;

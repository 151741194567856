// import orange from "../assets/images/orange-slice.png";

const Post = ({ logo, logo2 }) => {
  return (
    <>
      <section id="post-section">
        <div className="post">
          <div className="post-info">
            {/* <img src={logo} alt="Logo de Loretta3" className="logo" /> */}
            <span id="post-one">
              <h1>loretta3</h1>
            </span>
            <span id="post-two">
              <h2>
                <span>IT</span>&nbsp;SOLUTIONS
              </h2>
            </span>
            {/* <p>!Soluciones informáticas a tu medida!</p> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default Post;

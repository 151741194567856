import firebase from "firebase/compat/app";
import "firebase/compat/auth";
// import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBgzceWAXchPe_rolFT2ZTdg1Ap4-AChlg",
  authDomain: "loretta3.firebaseapp.com",
  projectId: "loretta3",
  storageBucket: "loretta3.firebasestorage.app",
  messagingSenderId: "809878919808",
  appId: "1:809878919808:web:2f6ea4f401d6bbdaa62465",
  measurementId: "G-L6SDGB5X8G",
};

try {
  firebase.initializeApp(firebaseConfig);
} catch (err) {
  if (!/already exists/.test(err.message)) {
    console.error("Firebase initialization error", err.stack);
  }
}

const fire = firebase;
// const analitics = getAnalytics(firebase);
export default fire;

import { siteConstants } from "../assets/constants/site.constants";
import toggleMenu from "./toggleMenu";

const NavBar = () => {
  return (
    <header className="sticky-bg">
      <nav>
        <div className="nav-links">
          <i className="fa fa-bars" onClick={toggleMenu}></i>
          <a href="#post-section" className="hero-link">
            loretta3 | IT Solutions
          </a>

          <div id="nav-links-sub">
            <ul>
              <li>
                <a href="#about-us">{siteConstants.ABOUT}</a>
              </li>
              <li>
                <a href="#our-products">{siteConstants.PRODUCTS}</a>
              </li>
              <li>
                <a href="#contact">{siteConstants.CONTACT}</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;

import { configureStore } from "@reduxjs/toolkit";
import siteSlice from "./siteSlice";
import armadilloSlice from "./armadilloSlice";
import pringamosaSlice from "./pringamosaSlice";

const store = configureStore({
  reducer: {
    site: siteSlice,
    armadillo: armadilloSlice,
    pringamosa: pringamosaSlice,
  },
});

export default store;

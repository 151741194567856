import { siteConstants } from "../assets/constants/site.constants";

const Footer = () => {
  return (
    <section id="footer-section">
      <div className="footer-row">
        <p>Copyright &copy; loretta3 2024</p>
        <div className="footer-nav-links">
          <ul>
            <li>
              <a href="#post-section">{siteConstants.HOME} </a>
            </li>
            <li>
              <a href="#about-us">{siteConstants.ABOUT}</a>
            </li>
            <li>
              <a href="#our-products">{siteConstants.PRODUCTS}</a>
            </li>
            <li>
              <a href="#contact">{siteConstants.CONTACT}</a>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;

import { useDispatch, useSelector } from "react-redux";
import { Button } from "reactstrap";
import { objAdded } from "../store/siteSlice";

const Test = () => {
  const { site } = useSelector((state) => state.site);

  const dispatch = useDispatch();
  const handleClick = () => {
    handleClick(dispatch(objAdded(site)));
  };

  return <Button onClick={handleClick}>Add register</Button>;
};

export default Test;

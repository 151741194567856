const About = () => {
  return (
    <section id="about-us">
      <meta
        name="description"
        content="Loretta3: Impulsamos el crecimiento empresarial con soluciones IT personalizadas. Ofrecemos desarrollo a medida, presencia digital y consultoría estratégica."
      />
      <div className="about-us-container">
        <div className="about-us-row reveal">
          <div className="about-us-col">
            <article className="about-content">
              <h3>Impulso a la Presencia Digital</h3>
              <p>
                En Loretta3, ayudamos a empresas a destacar en el mundo digital.
                Creamos estrategias y herramientas que fortalecen su visibilidad
                online, para que cada negocio conecte y crezca en el entorno
                digital.
              </p>
            </article>
          </div>
          <div className="about-us-col">
            <article className="about-content">
              <h3>Desarrollo IT a la Medida</h3>
              <p>
                Creamos soluciones tecnológicas adaptadas a cada cliente. Desde
                software personalizado hasta automatización de procesos,
                mejoramos la eficiencia y reducimos costos para potenciar la
                competitividad de nuestros clientes.
              </p>
            </article>
          </div>
          <div className="about-us-col">
            <article className="about-content">
              <h3>Consultoría Estratégica para el Crecimiento</h3>
              <p>
                Acompañamos a las empresas en su transformación digital. Con
                asesoría técnica y soluciones estratégicas, ayudamos a nuestros
                clientes a adoptar tecnologías que impulsan su crecimiento y
                sostenibilidad.
              </p>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

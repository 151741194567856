import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";

export const fetch = createAsyncThunk(
  `${siteConstants.CUST_ARMADILLO}/fecth`,
  async (payload) => {
    return [];
  }
);

// src/reducers/rootReducer.js
const initialState = {
  name: "Artesanías Armadillo",
  title: "Artesanías Armadillo - Correaje y Cinturones de Cuero Hechos a Mano",
  description:
    "Descubre las correas de cuero personalizadas hechas a mano de Artesanías Armadillo. Calidad y durabilidad garantizadas.",
  keywords:
    "correas de cuero, cinturones, artesanía, productos personalizados, cuero legítimo",
  url: "https://loretta3.com/landing/artesanias-armadillo",
  image: "https://loretta3.com/images/armadillo.jpg",
  robots: "index, follow",
  author: "Loretta3",

  products: [
    {
      id: 1,
      name: "Correas para Perros",
      description:
        "Hechas a mano con cuero legítimo, resistentes y personalizables.",
      image: "correas-perros.jpg",
    },
    {
      id: 2,
      name: "Cinturones Personalizados",
      description: "Elige el diseño que más te guste, adaptado a tu estilo.",
      image: "correas-cinturones.jpg",
    },
    {
      id: 3,
      name: "Accesorios de Cuero",
      description:
        "Productos artesanales para tu día a día, de gran durabilidad.",
      image: "accesorios-cuero.jpg",
    },
  ],
  message: null,
  loading: false,
  error: null,
};

const armadilloSlice = createSlice({
  name: siteConstants.CUST_ARMADILLO,
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = armadilloSlice.actions;
export default armadilloSlice.reducer;

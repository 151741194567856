import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Home from './features/web/Home';
import { Provider, useSelector } from "react-redux";
import { scrollTop, whatsAppBt } from "./features/util/forms.util";
import store from "./store";
import PrivacyPolicy from "./components/other/PrivacyPolicy";

import Test from "./_components/Test";
import LaPringamosaHome from "./hub/lapringamosa/LaPringamosaHome";
import Loretta3Home from "./hub/loretta3/Loretta3Home";
import Web from "./components/Web";

function App() {
  const { company } = useSelector((state) => state.site);
  return (
    <>
      <Provider store={store}>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Web />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="test" element={<Test />} />

            <Route
              path="landing/la-pringamosa"
              element={<LaPringamosaHome />}
            />

            {/* <Route
              path="landing/ancestral-cafe-galeria"
              element={<AncestralCafeGaleria />}
            />
            <Route
              path="landing/artesanias-armadillo"
              element={<ArtesaniasArmadillo />}
            />
            <Route
              path="landing/ashka-soluciones-financieras"
              element={<AshkaSolucionesFinancieras />}
            /> */}
            {/* <Route path="" element={<></>}/> */}
          </Routes>
        </BrowserRouter>
        {whatsAppBt(company)}
      </Provider>
      {scrollTop()}
    </>
  );
}

export default App;

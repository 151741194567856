const PrivacyPolicy = () => {
  return (
    <section id="privacy-policy" aria-labelledby="privacy-policy-title">
      <h2 id="privacy-policy-title">Política de Privacidad</h2>

      <p>
        En Loretta3, valoramos tu privacidad y nos comprometemos a proteger tus
        datos personales. Esta política de privacidad explica qué datos
        recopilamos, cómo los usamos, y los derechos que tienes sobre tu
        información.
      </p>

      <h3>1. Información que Recopilamos</h3>
      <p>
        Recopilamos información personal que nos proporcionas directamente, como
        tu nombre, correo electrónico, número de teléfono y cualquier otra
        información que decidas compartir a través de nuestros formularios o
        comunicaciones. También podemos recolectar datos automáticamente, como
        tu dirección IP, el navegador que utilizas y tu comportamiento en
        nuestro sitio web a través de cookies y tecnologías similares.
      </p>

      <h3>2. Uso de la Información</h3>
      <p>
        Utilizamos tus datos personales para:
        <ul>
          <li>Proporcionarte nuestros productos y servicios.</li>
          <li>Personalizar tu experiencia en nuestra web.</li>
          <li>Mejorar nuestro sitio y ofrecerte contenido relevante.</li>
          <li>
            Comunicarnos contigo sobre nuestros servicios, promociones y
            actualizaciones.
          </li>
        </ul>
      </p>

      <h3>3. Compartición de la Información</h3>
      <p>
        No compartimos tus datos personales con terceros, excepto cuando sea
        necesario para:
        <ul>
          <li>Cumplir con la ley.</li>
          <li>Proteger nuestros derechos y seguridad.</li>
          <li>
            Ofrecer nuestros servicios a través de proveedores externos que
            cumplen con estándares de seguridad y confidencialidad.
          </li>
        </ul>
      </p>

      <h3>4. Derechos sobre tu Información</h3>
      <p>
        Tienes el derecho a acceder, actualizar o solicitar la eliminación de
        tus datos personales en cualquier momento. También puedes solicitar que
        dejemos de procesar tus datos o retirar tu consentimiento para recibir
        comunicaciones de marketing.
      </p>

      <h3>5. Seguridad de los Datos</h3>
      <p>
        Implementamos medidas de seguridad para proteger tu información,
        evitando accesos no autorizados, alteraciones o divulgación de tus
        datos.
      </p>

      <h3>6. Cambios en la Política de Privacidad</h3>
      <p>
        Loretta3 se reserva el derecho de actualizar esta política de privacidad
        periódicamente. Cualquier cambio se publicará en esta página, y te
        recomendamos revisarla con regularidad.
      </p>

      <h3>7. Contacto</h3>
      <p>
        Si tienes alguna pregunta o inquietud sobre esta política de privacidad,
        contáctanos en:
        <br />
        <strong>Correo electrónico:</strong>{" "}
        <a href="mailto:serrano.lorena@loretta3.com">
          serrano.lorena@loretta3.com
        </a>
        <br />
        <strong>Teléfono:</strong> +57 (324) 430-2025
      </p>
    </section>
  );
};

export default PrivacyPolicy;

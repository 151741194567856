import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { siteConstants } from "../assets/constants/site.constants";
import { formatDateDDMMYYYHHMM_min } from "../util/common.util";
import fire from "../fire";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  setDoc,
  deleteDoc,
  doc,
  orderBy,
  // setDoc,
  // onSnapshot,
  query,
  where,
  // orderBy,
  limit,
  getDoc,
  // getDoc,
  // startAt,
  // endAt,
} from "firebase/firestore";

export const fetch = createAsyncThunk(
  `${siteConstants.LORETTA3}/fecth`,
  async (payload) => {
    return [];
  }
);

export const objAdded = createAsyncThunk(
  `${siteConstants.LORETTA3}/objAdded`,
  async (payload) => {
    // payload.createdAt = new Date();

    const db = getFirestore(fire.auth().app);
    const objs = await addDoc(collection(db, "sites"), payload, {
      merge: true,
    }).then((snap) => {
      return {
        ...payload,
        // createdAt: formatDateDDMMYYYHHMM(payload.createdAt),
        id: snap.id,
      };
    });
    return objs;
  }
);

export const objUpdated = createAsyncThunk(
  `${siteConstants.LORETTA3}/objUpdated`,
  async (payload) => {
    const { coll, obj } = payload;
    const db = getFirestore(fire.auth().app);
    // let createdAt_ = obj.createdAt;
    delete obj.createdAt;
    const docRef = doc(db, coll, obj.id);
    const objs = await setDoc(docRef, obj, { merge: true })
      .then((o) => {
        return {
          ...obj,
          // createdAt: createdAt_,
          // updatedAt: formatDateDDMMYYYHHMM(payload.updatedAt),
        };
      })
      .catch((e) => {
        throw e;
      });
    return objs;
  }
);

const initialState = {
  site: {
    id: "loretta3",
    title: "Loretta3 | Soluciones IT",
    description:
      "Loretta3 potencia tu negocio con desarrollo de software a medida, presencia digital, y consultoría en transformación digital. Optimiza procesos, aumenta tu rentabilidad y destaca en el mercado.",
    themeId: "theme_loretta3",
    companyId: "company_loretta3",
    socialLinksId: "social_loretta3",
    catalogId: "catalog_loretta3",
    customers: [
      "ancestral-cafe-galeria",
      "artesanias-armadillo",
      "ashka-soluciones-financieras",
    ],
  },

  title: "Loretta3 | Soluciones IT",
  description:
    "Loretta3 potencia tu negocio con desarrollo de software a medida, presencia digital, y consultoría en transformación digital. Optimiza procesos, aumenta tu rentabilidad y destaca en el mercado.",
  keywords:
    "Loretta3, desarrollo a medida, soluciones IT, presencia digital, consultoría en transformación digital, optimización de procesos, automatización empresarial, desarrollo de software, Kit Digital, creación de páginas web, marketing digital, SEO, Google Maps, redes sociales, Hub de negocios, Loretta Business Hub, competitividad IT, turismo y negocios locales",
  url: "https://loretta3.com",
  image: "https://loretta3.com/logo.png",
  robots: "index, follow", // Controla la indexación y el seguimiento de enlaces
  author: "Loretta3 Soluciones IT", // Autor o nombre de la empresa
  theme: {
    primaryColor: "#001c30",
    secondaryColor: "#230043",
    backgroundColor: "#e3e3d0",
    textColor: "#212529",
  },
  company: {
    name: "loretta3",
    whatsapp: "573244302025",
  },
  facebook: "https://www.facebook.com/profile.php?id=61561377935980",
  instagram: "https://www.instagram.com/loretta3_it/",
  linkedin: "https://www.linkedin.com/company/loretta3",
  twitter: "",
  youtube: "",
  tiktok: "",

  customers: [
    {
      name: "Ashka Soluciones Financieras",
      title: "Ashka Soluciones Financieras - Créditos para Pensionados",
      description:
        "Encuentra la mejor opción de crédito para pensionados y empleados públicos con Ashka Soluciones Informáticas. Asesoría personalizada y la mejor oferta en créditos.",
      keywords:
        "créditos hipotecarios, créditos libranza, financiación, pensionados, empleados públicos",
      url: "https://loretta3.com/landing/ashka-soluciones-financieras",
      image: "https://loretta3.com/images/cust/ashka.png",
      robots: "index, follow",
      author: "Loretta3",
    },
    {
      name: "Ancestral Café Galería",
      title: "Ancestral Café Galería - Café, Postres y Arte en Zipaquirá",
      description:
        "Visita Ancestral Café Galería en el centro histórico de Zipaquirá y disfruta de nuestros postres, café, libros y arte en un ambiente único.",
      keywords:
        "café, postres, arte, libros, Zipaquirá, café en Zipaquirá, galería de arte",
      url: "https://loretta3.com/landing/ancestral-cafe-galeria",
      image: "https://loretta3.com/images/ancestral.jpg",
      robots: "index, follow",
      author: "Loretta3",
    },
    {
      name: "Artesanías Armadillo",
      title:
        "Artesanías Armadillo - Correaje y Cinturones de Cuero Hechos a Mano",
      description:
        "Descubre las correas de cuero personalizadas hechas a mano de Artesanías Armadillo. Calidad y durabilidad garantizadas.",
      keywords:
        "correas de cuero, cinturones, artesanía, productos personalizados, cuero legítimo",
      url: "https://loretta3.com/landing/artesanias-armadillo",
      image: "https://loretta3.com/images/armadillo.jpg",
      robots: "index, follow",
      author: "Loretta3",
    },
  ],
  message: null,
  loading: false,
  error: null,
};

const siteSlice = createSlice({
  name: "site",
  initialState: initialState,
  reducers: {
    init(state, action) {
      state = { ...initialState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetch.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(fetch.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(fetch.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      })
      .addCase(objAdded.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(objAdded.fulfilled, (state, action) => {
        // state.obj = null;
        state.loading = false;
        state.info = "Registro exitoso";
      })
      .addCase(objAdded.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error?.message;
      });
  },
});

export const { init } = siteSlice.actions;
export default siteSlice.reducer;
